<template>
  <form @submit.prevent="onSubmit">
    <div
      class="card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions mb-1"
    >
      <div class="card-header mb-2">
        <h4 class="mb-0">
          Opens
          <small class="mb-0 d-block" style="margin-top: 5px">
            Para gerar o token na plataforma Opens, <a href="https://manager.opens.com.br/v1/users/profile" target="_blank">clique aqui</a>.
          </small>
        </h4>
        
      </div>
      <div class="card-body">
        <div class="form-row">
          <div class="col-md-6">
            <label>Token</label>
            <input
              class="form-control"
              type="text"
              v-model="formOpens.token"
              :class="{ 'is-invalid': $v.formOpens.token.$error }"
            />
          </div>
          <div class="col-md-6">
            <label>Company ID</label>
            <input
              class="form-control"
              type="text"
              v-model="formOpens.company_id"
              :class="{ 'is-invalid': $v.formOpens.company_id.$error }"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="form-row mt-1 justify-content-end">
      <div class="col-md-3">
        <button type="submit" class="btn btn-block btn-success" :disabled="submitted">
          <div v-if="submitted">
            <b-spinner small variant="light" />
          </div>
          <span v-else>Salvar</span>
        </button>
      </div>
    </div>  
  </form>
</template>

<script>
import {
  BTabs,
  BForm,
  BTab,
  BCard,
  BCol,
  BSpinner,
  VBTooltip,
} from "bootstrap-vue";
import { required } from "vuelidate/lib/validators";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";

export default {
  components: {
    BTabs,
    BCol,
    BForm,
    BTab,
    BCard,
    BSpinner,
    vSelect,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      submitted: false,
      formOpens: {
        token: "",
        company_id: "",
      },
    }
  },
  validations: {
    formOpens: {
      token: { required },
      company_id: { required },
    }
  },
  methods: {
    async fetchOpens() {
      try {
        await this.$store.dispatch("Config/fetchSchoolOpens").then((res) => {
          this.formOpens = res
        });
      } catch (error) {
        console.error("Error fetching bling:", error);
      }
    },
    async onSubmit() {
      this.$v.formOpens.$touch();
      if (!this.$v.formOpens.$error) {
        this.submitted = true;
        try {
          await this.$store.dispatch("Config/updateSchoolOpens", this.formOpens).then(() => {
            this.notifyDefault("success");
          });       
        } catch (error) {
          console.error("Error updating steps:", error);
        } finally {
          this.submitted = false;
        }
      }
    },
  },
  created() {
    this.fetchOpens();
  }
};
</script>